import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription, interval, of, switchMap, takeUntil } from 'rxjs';
import { MenuItem, MenuRightService } from 'src/app/components/menus/right/menu-right.service';
import { UnitsService } from 'src/app/services/units.service';
import { UsersService } from 'src/app/services/users.service';
import { UserByUnitEntity, UserEntity } from 'src/app/models/users.models';
import { UnitByUserEntity, UnitEntity } from 'src/app/models/units.models';
import { AppTheme, Group, Language, Role, TimeFormat } from 'src/app/types/users.types';
import { AlertController, NavController, Platform, ToastController } from '@ionic/angular';
import { PlatformService } from 'src/app/services/platform.service';
import { SocketioService } from 'src/app/services/socketio.service';
import { WebsocketEventName } from 'src/app/models/events-names.models';
import { WebsocketMessage } from 'src/app/models/websocket.models';
import { TranslateService } from '@ngx-translate/core';
import { AnomaliesService } from 'src/app/services/anomalies.service';
import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TimeWindowService } from 'src/app/services/time-window.service';
import { TrackByComponent } from 'src/app/utilities/track-by.component';
import { PushService } from 'src/app/services/push.service';

@Component({
    selector: 'app-menu-right',
    templateUrl: './menu-right.component.html',
    styleUrls: ['./menu-right.component.scss']
})
export class MenuRightComponent extends TrackByComponent {
    public indoor = false;
    public logged = false;
    private subs: Subscription;
    public loggedUserEntity: UserEntity;
    public indoorUserEntity: UserByUnitEntity;
    public indoorUnitEntity: UnitEntity;

    public menuItems: MenuItem[] = [];
    public theme: AppTheme;

    public outdoor: boolean;
    public enable: boolean;
    public timeZone: string;
    public language: Language;
    public hour12: boolean;
    public unitOnline: boolean;
    public unitName: string;
    public platformName: string[];
    public outdoorAnomalies = 0;
    public indoorAnomalies = 0;
    private kickOut = false;
    public pushesMap: Map<string, number> = new Map();

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(
        private router: Router,
        private navCtrl: NavController,
        private menuRightService: MenuRightService,
        private usersService: UsersService,
        private unitsService: UnitsService,
        private anonaliesUnitService: AnomaliesService,
        private platformService: PlatformService,
        private socketioService: SocketioService,
        private alertController: AlertController,
        public toastController: ToastController,
        private translate: TranslateService,
        private authService: AuthService,
        private pushService: PushService,
        private timeWindowService: TimeWindowService
    ) {
        super();
        this.subs = new Subscription();
        this.enable = true;
        this.platformName = this.platformService.platforms();

        //Gestiamo i dati dell'utente loggato
        this.subs.add(
            this.usersService.loggedUserObserver.subscribe((user) => {
                this.loggedUserEntity = user;
                if (this.loggedUserEntity.userId) {
                    this.logged = true;
                    this.theme = this.loggedUserEntity.theme;
                    this.language = this.loggedUserEntity.language;
                    this.hour12 = this.loggedUserEntity.timeFormat === TimeFormat.h12 ?? false;

                    //Verifichiamo se l'utente è bannato
                    this.checkBannedUser(user);

                    //Aggiorniamo il menu
                } else {
                    this.logged = false;
                }
                this.updateMenuItems();
            })
        );

        //Gestiamo i dati dell'utente indoor
        this.subs.add(
            this.usersService.indoorUserObserver.subscribe((user) => {
                this.indoorUserEntity = user;
                if (this.indoorUserEntity.userId) {
                    this.indoor = true;
                } else {
                    this.indoor = false;
                }
                //Aggiorniamo il menu
                this.updateMenuItems();
            })
        );

        //Gestiamo i dati della unit indoor
        this.subs.add(
            this.unitsService.indoorUnitObserver.subscribe(async (unit) => {
                this.indoorUnitEntity = unit;
                if (this.indoorUnitEntity.unitId) {
                    this.timeZone = this.indoorUnitEntity.timeZone;
                    this.unitName = this.indoorUnitEntity.unitName;
                    this.unitOnline = this.indoorUnitEntity.online;

                    //Verifichiamo se l'utente è autorizzato a stare dentro la centrale
                    this.checkPermessoDiSoggiorno(unit);
                    this.checkUnitOnline(unit);
                }
            })
        );

        //Gestiamo i dati delle anomalie outdoor
        this.subs.add(
            this.anonaliesUnitService.anomaliesByUserEntitiesObserver.subscribe((anomalies) => {
                this.outdoorAnomalies = anomalies.length;
            })
        );

        //Gestiamo i dati delle anomalie indoor
        this.subs.add(
            this.anonaliesUnitService.anomaliesByUnitEntitiesObserver.subscribe((anomalies) => {
                this.indoorAnomalies = anomalies.length;
            })
        );
        //Riceviamo le notifche push
        this.subs.add(
            this.pushService.pushesByUnitEntitiesObserver.subscribe((pushes) => {
                let count = pushes.length;
                let unitId = pushes[0].unitId;
                this.pushesMap.set(unitId, count);
                console.log(this.pushesMap);
                if (this.indoorUnitEntity) {
                    const numero = this.pushesMap.get(this.indoorUnitEntity.unitId);
                    console.log(numero);
                }
            })
        );
        //Gestiamo il colore del menu selezionato
        this.subs.add(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.menuItems.forEach((item) => {
                        if (this.router.url.includes(item.url)) {
                            item.color = this.theme;
                            item.textColor = '#fff';
                        } else {
                            item.textColor = '#858585';
                            item.color = 'transparent';
                        }
                    });
                }
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public ngOnInit(): void {
        //Ci mettiamo in ascolto dei messaggi websocket
        this.socketioService.getMessage(WebsocketEventName.rebootInProgress).subscribe(async (kafkaMessage: WebsocketMessage<any>) => {
            if (kafkaMessage?.payload?.rebootInProgress) {
                const toast = await this.toastController.create({
                    header: this.translate.instant('Attenzione!'),
                    message: this.translate.instant('Riavvio centrale in corso...'),
                    icon: '1633',
                    position: 'middle',
                    duration: 5000,
                    color: 'warning'
                });
                toast.present();
            }
        });

        /*------------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------------*/
        interval(60000).subscribe(() => {
            // Verifica se l'utente è loggato e se esiste un'unità indoor
            if (this.loggedUserEntity.userId && this.indoorUnitEntity.unitId) {
                // Effettua la chiamata API per scatenare l'observable che aggiorna i dati dell'utente indoor e checca il permesso di soggiorno
                this.usersService.readIndoorUser(this.loggedUserEntity.userId, this.indoorUnitEntity.unitId).subscribe();
            }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public async checkPermessoDiSoggiorno(unit: UnitByUserEntity | UserByUnitEntity) {
        //Verifichiamo se l'utente è autorizzato a stare dentro la centrale (disabilitato)
        if (!this.timeWindowService.userEnable(unit)) {
            //Se non abbiamo ancor triggerato la disconnessione
            if (!this.kickOut) {
                //Evitiamo una doppi notifica
                this.kickOut = true;
                const alert = await this.alertController.create({
                    header: this.translate.instant('Attenzione!'),
                    subHeader: this.translate.instant('Utente non autorizzato'),
                    message: this.translate.instant('Stai per essere disconnesso.'),
                    buttons: [
                        {
                            text: this.translate.instant('button.confirm'),
                            cssClass: this.theme,
                            handler: () => {
                                //Forziamo la navigazione verso outdoor
                                this.navCtrl.navigateRoot(`/outdoor`, { replaceUrl: true });
                                this.kickOut = false;
                            }
                        }
                    ]
                });
                await alert.present();
            }
        }

        //Verifichiamo se l'utente è autorizzato a stare dentro la centrale (fuori orario)
        if (!this.timeWindowService.userWinTime(unit)) {
            //Se non abbiamo ancor triggerato la disconnessione
            if (!this.kickOut) {
                //Evitiamo una doppi notifica
                this.kickOut = true;
                const alert = await this.alertController.create({
                    header: this.translate.instant('Attenzione!'),
                    subHeader: this.translate.instant('Fuori orario di accesso'),
                    message: this.translate.instant('Stai per essere disconnesso.'),
                    buttons: [
                        {
                            text: this.translate.instant('button.confirm'),
                            cssClass: this.theme,
                            handler: () => {
                                //Forziamo la navigazione verso outdoor
                                this.navCtrl.navigateRoot(`/outdoor`, { replaceUrl: true });
                                this.kickOut = false;
                            }
                        }
                    ]
                });
                await alert.present();
            }
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
   
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public async checkUnitOnline(unit: UnitByUserEntity) {
        //Se la centrale è offline, avvisiamo l'utente e lo disconnettiamo
        if (!unit.online) {
            //Se non abbiamo ancor triggerato la disconnessione
            if (!this.kickOut) {
                //Evitiamo una doppi notifica
                this.kickOut = true;
                const alert = await this.alertController.create({
                    header: this.translate.instant('Attenzione!'),
                    subHeader: this.translate.instant('Centrale offline'),
                    message: this.translate.instant('Stai per essere disconnesso.'),
                    buttons: [
                        {
                            text: this.translate.instant('button.confirm'),
                            cssClass: this.theme,
                            handler: () => {
                                //Forziamo la navigazione verso outdoor
                                this.navCtrl.navigateRoot(`/outdoor`, { replaceUrl: true });
                                this.kickOut = false;
                            }
                        }
                    ],
                    backdropDismiss: false
                });
                const backdrop = document.querySelector('ion-backdrop');
                if (backdrop) {
                    backdrop.addEventListener(
                        'click',
                        (e) => {
                            e.stopPropagation();
                        },
                        true
                    );
                }
                await alert.present();
            }
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public async checkBannedUser(user: UserEntity | UserByUnitEntity) {
        if (user.ban === null || user.ban === undefined || user.ban === false) {
        } else {
            //Se non abbiamo ancor triggerato la disconnessione
            if (!this.kickOut) {
                //Evitiamo una doppi notifica
                this.kickOut = true;
                const alert = await this.alertController.create({
                    header: this.translate.instant('Attenzione!'),
                    subHeader: this.translate.instant('Utente bannato'),
                    message: this.translate.instant('Stai per essere disconnesso.'),
                    buttons: [
                        {
                            text: this.translate.instant('button.confirm'),
                            cssClass: this.theme,
                            handler: () => {
                                //Forziamo l'uscita dell'utente dal'applicazione
                                this.authService.logOut();
                                this.kickOut = false;
                            }
                        }
                    ]
                });
                await alert.present();
            }
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public updateMenuItems() {
        //Siamo loggati
        if (this.logged) {
            //Siamo outdoor
            if (!this.indoor) {
                switch (this.loggedUserEntity.group) {
                    case Group.administrator:
                        this.menuItems = this.menuRightService.menu.outdoor.adminItems;
                        break;
                    case Group.support:
                        this.menuItems = this.menuRightService.menu.outdoor.supportItems;
                        break;
                    case Group.customer:
                        this.menuItems = this.menuRightService.menu.outdoor.customerItems;
                        break;
                    default:
                        this.menuItems = [];
                        break;
                }
            }
            //Siamo indoor
            else {
                switch (this.indoorUserEntity.role) {
                    case Role.service:
                        this.menuItems = this.menuRightService.menu.indoor.serviceItems;
                        break;
                    case Role.owner:
                        this.menuItems = this.menuRightService.menu.indoor.ownerItems;
                        break;
                    case Role.member:
                        this.menuItems = this.menuRightService.menu.indoor.memberItems;
                        break;
                    case Role.guest:
                        this.menuItems = this.menuRightService.menu.indoor.guestItems;
                        break;
                    default:
                        this.menuItems = [];
                        break;
                }
            }
        }
        //Non siamo loggati
        else {
            this.menuItems = [];
        }

        //Aggiorniamo il colore del menu selezionato
        this.menuItems.forEach((item) => {
            if (this.router.url.includes(item.url)) {
                item.color = this.theme;
                item.textColor = '#fff';
            } else {
                item.textColor = '#858585';
                item.color = 'transparent';
            }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Click delle voci di menu
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    routerLink(url: string) {
        this.router.navigateByUrl(url, { replaceUrl: true });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
