<ion-item [detail]="detail" lines="inset" class="ion-no-padding ion-no-margin" (click)="userEdit()">
    <ion-thumbnail slot="start">
        <ion-img *ngIf="avatar" alt="" [src]="avatar"></ion-img>
    </ion-thumbnail>
    <ion-label>
        <ion-text [class]="className">{{ user_.firstName }} {{ user_.lastName }}</ion-text>
        <ion-text [class]="classEmail" class="email">{{ user_.role | uppercase }} - {{ user_.email }}</ion-text>
    </ion-label>
    <status-badge *ngIf="!userAllowed" slot="end" [status]="'user.not-allowed'" [color]="'warning'"></status-badge>
    <status-badge *ngIf="!userEnable" slot="end" [status]="'user.disable'" [color]="'danger'"></status-badge>
</ion-item>
