<ion-content>
    <item-section-header title="unit-outdoor-users.modal.title" (closeModal$)="closeModal()" [iconClose]="true"></item-section-header>
    <ion-item *ngFor="let user of utenze" lines="inset">
        <ion-thumbnail slot="start">
            <ion-img alt="" [src]="user.imageUrl" style="border-radius: 100%"></ion-img>
        </ion-thumbnail>
        <ion-label>
            <ion-text [class]="className">{{ user.firstName }} {{ user.lastName }}</ion-text>
            <br />
            <ion-text [class]="classEmail">{{ user.email }}</ion-text>
        </ion-label>
        <ion-button *ngIf="enter" (click)="sendingMail()" [color]="theme" size="default" expand="block"><ion-icon name="mail-outline"></ion-icon></ion-button>
    </ion-item>
</ion-content>
