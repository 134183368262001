<ng-container *ngIf="deletable || deletable || favourite">
    <ion-list style="border-radius: 10px; padding: 8px 0">
        <ion-item [button]="true" [detail]="true" (click)="close(PopoverAction.edit)" *ngIf="editable">Modifica</ion-item>
        <ion-item [button]="true" [detail]="true" (click)="close(PopoverAction.technology)" *ngIf="technology">Tecnologie</ion-item>
        <ion-item [button]="true" [detail]="true" (click)="close(PopoverAction.controls)" *ngIf="controls">Controlli</ion-item>
        <ion-item [button]="true" [detail]="true" (click)="close(PopoverAction.immediateSend)" *ngIf="immediateSend">Invia immediatamente</ion-item>
        <!-- <ion-item [button]="true" [detail]="true" (click)="close(PopoverAction.graph)" *ngIf="graph">Grafici</ion-item> -->

        <ion-item [button]="true" [detail]="true" (click)="close(PopoverAction.favoriteAdd)" *ngIf="favourite === PopoverFavouriteAction.add">
            Aggiungi ai preferiti
        </ion-item>
        <ion-item [button]="true" [detail]="true" (click)="close(PopoverAction.favoriteDelete)" *ngIf="favourite === PopoverFavouriteAction.delete">
            Rimuovi dai preferiti
        </ion-item>

        <ion-item [button]="true" type="button" [detail]="true" (click)="close(PopoverAction.delete)" *ngIf="deletable">
            <ion-text color="danger">Elimina</ion-text>
        </ion-item>
    </ion-list>
</ng-container>
