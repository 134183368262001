import { TimeWindowService } from 'src/app/services/time-window.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserByUnitEntity } from 'src/app/models/users.models';
import { PlatformService } from 'src/app/services/platform.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
    selector: 'admitted-user-item',
    templateUrl: './admitted-user-item.component.html',
    styleUrls: ['./admitted-user-item.component.scss']
})
export class AdmittedUserItemComponent {
    // @Input() user: UserByUnitEntity;
    @Input() set user(value: UserByUnitEntity) {
        this.usersService.userDownloadImage(value.userId).subscribe(async (image) => {
            if (image) {
                const blob: Blob = new Blob([new Uint8Array(<Buffer>image['data'])], { type: 'image/webp' });
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = () => (this.avatar = <string>reader.result);
            } else {
                this.avatar = 'assets/backgrounds/user-default.webp';
            }
        });
        this.userAllowed = this.timeWindowService.userWinTime(value);
        this.userEnable = this.timeWindowService.userEnable(value);
        this.user_ = value;
    }
    public className: string;
    public classEmail: string;
    public avatar: string;
    public userAllowed: boolean;
    public userEnable: boolean;
    public user_: UserByUnitEntity;
    @Input() detail: boolean = true;
    @Output() userEdit$ = new EventEmitter();

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private platformService: PlatformService, private timeWindowService: TimeWindowService, private usersService: UsersService) {
        this.setStyle();
        this.platformService.resize.subscribe(async () => this.setStyle());
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit() {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userEdit = (): void => this.userEdit$.emit();

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    private setStyle = () => {
        if (this.platformService.isDesktop()) {
            this.className = 'text-input-roboto-regular-16 display-block';
            this.classEmail = 'notification-title-regular-13 display-block';
        }
        if (this.platformService.isSmartphone()) {
            (this.className = 'text-input-roboto-regular-16 display-block'), (this.classEmail = 'label-items-mobile display-block');
        }
        if (this.platformService.isTablet()) {
            (this.className = 'text-input-roboto-regular-16 display-block'), (this.classEmail = 'label-items-mobile display-block');
        }
    };
}
