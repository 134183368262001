<ion-item [detail]="detail" lines="inset" class="ion-no-padding ion-no-margin">
    <ion-thumbnail slot="start">
        <ion-img *ngIf="avatar" alt="" [src]="avatar" style="border-radius: 100%"></ion-img>
    </ion-thumbnail>
    <ion-label>
        <ion-text [class]="className">{{ firstName }} {{ lastName }}</ion-text>
        <br />
        <ion-text [class]="classEmail">{{ email }}</ion-text>
    </ion-label>

    <ion-badge *ngIf="entityView === EntityView.usersIndoor && !userEnable" [color]=" 'warning'" slot="end" style="margin-right: 2px">
        {{ ('label.user.disabled' | translate) }}
    </ion-badge>
    <ion-button *ngIf="entityView === EntityView.usersPending" slot="end" (click)="userPendingReinvite()" class="grey" expand="block">
        <ion-icon slot="start" name="mail"></ion-icon>
        {{'button.send-mail' | translate}}
    </ion-button>
    <ion-icon
        *ngIf="entityView === EntityView.usersIndoor && userEnable"
        slot="end"
        name="chevron-forward"
        style="margin-right: -1%; margin-bottom: 5%"
    ></ion-icon>
</ion-item>
