<ion-item lines="none" detail="false" lines="none" class="ion-margin" [ngClass]="selectedClass">
    <ion-avatar slot="start" class="ion-no-margin ion-no-padding card-avatar" *ngIf="avatar" slot="start">
        <svg-icon [ngClass]="avatarSelected" [src]="'assets/ha/svg/' + avatar + '.svg'"></svg-icon>
    </ion-avatar>
    <ion-label style="padding-left: 16px">
        <ion-text [color]="selected ? 'title-card-'+theme : 'white-'+theme" [ngClass]="selected ?'text-input-roboto-bold-16' : 'text-input-roboto-regular-16'">
            {{ name | translate }}
            <br />
            <sub *ngIf="roomPath">{{ roomPath }}</sub>
        </ion-text>
        <p *ngIf="degreeValue" style="padding-top: 8px; color: rgba(92, 92, 92, 1)">{{degreeValue }}</p>
    </ion-label>
</ion-item>
