<ion-grid [formGroup]="form" class="ion-no-margin ion-no-padding">
    <ion-row class="ion-text-start ion-no-margin ion-no-padding">
        <ion-col size="12">
            <ion-item
                mode="md"
                [ngClass]="{
                    error: form.get(controlName).hasError('name') && (form.get(controlName).touched || form.get(controlName).dirty),
                    'ion-input-color-lili': theme === 'lili',
                    'ion-input-color-dark': theme !== 'lili' && !form.get(controlName).hasError('name') && !form.get(controlName).value,
                    'white-border-lili': theme === 'lili' && !form.get(controlName).hasError('name') && form.get(controlName).value,
                    'white-border-dark': theme !== 'lili' && !form.get(controlName).hasError('name') && form.get(controlName).value
                }"
                [ngStyle]="{
                    '--border-color': form.get(controlName).hasError('name')
                        ? 'red'
                        : !form.get(controlName).value
                        ? 'initial'
                        : form.get(controlName).valid
                        ? 'success'
                        : 'white'
                }"
                [lines]="lines"
                detail="false"
                class="ion-no-margin ion-no-padding"
            >
                <ion-label position="floating">
                    <ion-text class="text-input-roboto-regular-16" [ngClass]="'title-card-' + theme + ' medium-' + theme">
                        {{ label | translate }}
                    </ion-text>
                </ion-label>
                <ion-input
                    mode="md"
                    type="text"
                    class="text-input-roboto-regular-16"
                    fill="outline"
                    [formControlName]="controlName"
                    label="Name"
                    labelPlacement="floating"
                    placeholder="{{ placeholder | translate }}"
                    [clearInput]="clearInput"
                    [autofocus]="true"
                ></ion-input>
            </ion-item>
        </ion-col>
    </ion-row>
    <ion-row class="ion-text-start ion-no-margin ion-no-padding" style="margin-top: -13px">
        <ion-col size="12" class="ion-text-start ion-no-margin ion-no-padding">
            <sub
                class="card-subtitle-roboto-medium-11 success"
                *ngIf="!form.get(controlName).hasError('name') || !form.get(controlName).touched"
                style="visibility: hidden"
            >
                ..
            </sub>
            <sub
                class="card-subtitle-roboto-medium-11 error"
                *ngIf="form.get(controlName).hasError('name') && (form.get(controlName).touched || form.get(controlName).dirty)"
            >
                {{ invalidText }}
            </sub>
        </ion-col>
    </ion-row>
</ion-grid>
