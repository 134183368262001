import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'searchbar-card',
    templateUrl: './searchbar-card.page.html',
    styleUrls: ['./searchbar-card.page.scss']
})
export class SearchbarCardPage implements OnDestroy {
    @Input() theme: AppTheme;
    @Input() searchLabel: string;
    @Input() debounce = 150;
    @Input() size = 12;
    @Input() adaptWidth: string;

    @Output() searchTermChange = new EventEmitter<string>();
    @Output() clearSearchFilter = new EventEmitter<boolean>();

    onChangeSearchValue(event: Event): void {
        const {
            detail: { value }
        } = event as CustomEvent;
        this.searchTermChange.emit(value);
    }

    clearSearchLabel(): void {
        this.searchLabel = null;
        this.clearSearchFilter.emit(true);
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnDestroy() {}
}
